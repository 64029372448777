@import '../ui/variables';

.geecko-control {
  position: relative;
  width: 100%;
}

.geecko-input {
  width: 100%;
  box-sizing: border-box;
  color: $input-text-color;
  font-weight: 600;
  font-size: 16px;
  line-height: $input-line-height;
  border-radius: $input-border-radius;
  padding: $input-padding;
  border: 1px solid $input-border-color;
  background: $input-background;

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }

  &:hover {
    transition: border-color .275s;
    border-color: var(--button-secondary-border-bright);
  }

  /* &:focus {
    outline: none;
  } */

  &::placeholder {
    color: $input-placeholder-color;
  }

  &:focus,
  &:active {
    outline: none;
    background: $input-active-background;
    color: $input-active-text-color;

    border: 1px solid var(--button-secondary-border-bright) !important;
    box-shadow: 0 0 0 4px var(--button-secondary-focus-outline);
    transition: box-shadow .15s;
  }

  &__show-password {
    width: 40px;
    height: 40px;
    position: absolute;
    top: calc(50% - 20px);
    right: 4px;
    padding: 0;
    color: #4D4E57;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;

    svg {
      display: block;
    }
  }

  &.is-disabled {
    pointer-events: none;
    border: 1px solid transparent;
  }

  &:invalid,
  &.is-error {
    border-color: $input-error-border-color !important;
    
    &:focus {
      box-shadow: 0 0 0 4px var(--button-danger-secondary-focus-outline);
      transition: box-shadow .15s;
    }
  }
}
