.geecko-profile-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__wrapper {
    margin: 0 auto;

    width: 100%;
    max-width: 620px;

    flex-grow: 1;

    box-sizing: border-box;

    &--wide {
      max-width: 100%;
    }

    &--footer {
      padding-left: 72px;
      padding-right: 72px;

      max-width: 100%;
    }
  }

  &__panel {
    width: 100%;
    height: 50px;

    border-bottom: 1px solid #EBECED;

    box-sizing: border-box;
  }

  &__panel-wrapper {
    height: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__panel-wrapper-avatar {
    margin: 8px 0;

    width: 32px;
    height: 32px;

    background: var(--surface-two) no-repeat center;
    background-size: cover;

    flex-shrink: 0;

    border-radius: 20px;
    border: none;

    cursor: pointer;
  }

  &__middle {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 146px - 24px - 50px); /* 100% height - footer, footer top margin and header height */
  }

  &__menu {
    display: flex;
  }

  &__menu-wrapper {
    display: flex;
    align-items: center;
  }

  &__menu-wrapper-link {
    display: flex;
    margin-right: 20px;
    padding: 4px 10px;
    border-radius: 4px;
    align-items: center;
    // opacity: 0.5;
    color: var(--text-secondary);
    cursor: pointer;
    transition: opacity 200ms;

    &:hover {
      color: var(--text-primary);
    }

    &--active {
      background-color: var(--surface-default);
      color: var(--text-primary);
      opacity: 1;
    }
  }

  &__menu-wrapper-link-title {
    font-family: 'Proxima Nova', sans-serif;
    font-style: normal;
    font-size: 14px;
    letter-spacing: -0.01em;

    &--dropdown {
      color: var(--text-primary);
      font-size: 14px;
      line-height: 17px;
      font-family: Proxima Nova, sans-serif;
      font-style: normal;
      font-weight: normal;
    }

    // &--logout {
    //   color: #99A2B0;
    // }
  }

  &__content-title {
    font-family: 'Gotham Pro', sans-serif;
    font-weight: bold;
    font-style: normal;

    font-size: 36px;
    color: var(--text-primary);

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__footer {
    margin-top: 24px;

    height: 146px;

    font-family: Proxima Nova, sans-serif;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--text-tertiary);
    border-top: 1px solid var(--neutral-lines-two);
    background: var(--background);

    box-sizing: border-box;
  }

  &__footer-wrapper {
    padding-top: 24px;
    padding-bottom: 48px;

    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    box-sizing: border-box;
  }

  &__footer-wrapper-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 24px;
  }

  &__footer-wrapper-info-address {
    max-width: 472px;
  }

  &__footer-wrapper-language {
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;

    font-family: Proxima Nova, sans-serif;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #858A9C;
    align-items: center;

    cursor: pointer;

    transition: opacity 130ms;
  }

  &__locale {
    font-family: Proxima Nova, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #858A9C;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  &__spacer {
    flex: 1;
  }

  &__footer-wrapper-info-right {
    display: flex;
    flex-direction: column;
  }

  &__footer-wrapper-info-attachments {
    display: flex;
    justify-content: flex-end;
  }

  &__footer-wrapper-info-attachment {
    margin-left: 22px;

    text-decoration: underline;
    white-space: nowrap;

    cursor: pointer;
    color: var(--text-tertiary);

    transition: opacity 150ms;

    a {
      color: var(--text-tertiary);
    }

    &:hover {
      opacity: 0.7;
    }
  }

  @media (max-width: 1080px) {
    &__footer {
      height: auto;
    }

    .geecko-profile-layout__menu {
      border-bottom: 1px solid #EBECED;
    }

    .geecko-profile-layout__middle {
      flex-direction: column;
    }

    .geecko-profile-layout__menu-wrapper {
      display: flex;
      width: 100%;
      height: 55px;
      box-sizing: border-box;
    }

    .geecko-profile-layout__menu-wrapper-link {
      margin: 0 20px 0 0;
    }

    .geecko-profile-layout__menu-wrapper-link:hover {
      opacity: 1;
    }

    .geecko-profile-layout__menu {
      width: 100%;
    }

    .geecko-profile-layout__content-title {
      margin-bottom: 23px;

      font-size: 24px;
    }

    .geecko-profile-layout__footer {
      height: auto;
      margin-top: 72px;
    }

    .geecko-profile-layout__footer-wrapper-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }

    .geecko-profile-layout__footer-wrapper-info-address {
      margin: 30px 0;
    }

    .geecko-profile-layout__footer-wrapper-language {
      justify-content: flex-start;
    }

    .geecko-profile-layout__footer-wrapper-info-attachments {
      flex-wrap: wrap;
    }

    .geecko-profile-layout__footer-wrapper-info-attachment {
      margin-left: 0;
      margin-right: 22px;
    }
  }

  @media (max-width: 560px) {
    &__wrapper--footer {
        padding-left: 20px;
        padding-right: 20px;
    }

    &__footer-wrapper-info {
      flex-direction: column;
    }

    &__footer-wrapper-info-right {
      width: 100%;
      margin-top: 20px;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__footer-wrapper-info-attachments {
      flex-direction: column;
    }

    &__footer-wrapper-info-attachment {
      margin-left: 0;
    }

    &__footer-wrapper-language {
      margin-left: 10px;
    }

    .geecko-profile-layout__footer-wrapper-info-attachment:last-of-type {
      margin-bottom: 0;
    }
  }
}

.geecko-email-accordion {
  &__title {
    display: flex;
    justify-content: space-between;
  }

  &__email {
    color: #858A9C;
  }
}
