html {
  & body {
    --background: #EDEEF0;
    --surface-default: #FFFFFF;

    --surface-two: #F5F6F8;
    --surface-three: #DADDE5;
    --surface-four: #50556B;
    --surface-five: #F5F6F8;
    --surface-muted: #F5F6F8;
    --surface-warning: #FFEEA9;
    --surface-danger: #FFD3D3;
    --surface-success: #9BE4BA;
    --blackout-overlay: rgba(19, 17, 32, 0.8);

    --neutral-lines-one: #D5DADF;
    --neutral-lines-two: #C8CCD0;
    --inputs: #FFFFFF;
    --chips: #ECF1FA;
    --chips-text: #2F50C7;
    --print-borders-instead-of-fill: #D7DBE5;

    --text-primary: #101014;
    --text-secondary: #4D4E57;
    --text-tertiary: #858A9C;
    --text-contrast: #FFFFFF;
    --active-accent: #2F50C7;

    --button-primary:  #2F50C7;
    --button-primary-text: #FFFFFF;
    --button-primary-hover: #2A44A0;  
    --button-primary-pressed: #233A8A;
    --button-primary-focus-outline: #9AAFFF;
    --button-primary-loading: rgba(255, 255, 255, 0.25);

    --button-secondary-border: #2F50C7;
    --button-secondary-border-bright: #2F50C7;
    --button-secondary-text: #2F50C7;
    --button-secondary-hover: #E8F1FF;
    --button-secondary-pressed: #D8E6FF;
    --button-secondary-focus-outline: #9AAFFF;
    --button-secondary-loading: #DBE2FF;

    --button-danger-primary: #D34444;
    --button-danger-primary-text: #FFFFFF;
    --button-danger-primary-hover: #B13838;
    --button-danger-primary-pressed: #9B3030;
    --button-danger-primary-focus-outline: #FFB7B7;

    --button-danger-secondary-border: #D34444;
    --button-danger-secondary-text: #D34444;
    --button-danger-secondary-hover: #FFE8E8;
    --button-danger-secondary-pressed: #FFD8D8;
    --button-danger-secondary-focus-outline: #FFB7B7;
    --button-danger-secondary-loading: #FFDBDB;

    --negative-accent: #E44C4C;
    --accent-one-bright-different: #2F50C7;
    --accent-one-mellow-different: #2F50C7;
    --accent-two: #459667;
    --surface-accent-two: #459667;
    --accent-three: #3B96EA;
    --accent-four: #7355E9;
    --accent-five: #F0884E;


    --user-steps-line: rgba(218, 221, 230, 0);
    --user-steps-line-full: #dadde6;
    --vacancy-card-tag-border: #DADDE5;
    --active-accent2: #94A4DF;
    --step-bg-active: #F1F3F6;
    --stack-chip-border: #ECEEF4;
    --skillset-tag-bg1: #ebecef;
    --skillset-tag-bg2: #FBFBFB;

    &[data-geecko-theme='dark'] {
      --background: #12121A;
      --surface-default: #1F1F26;

      --surface-two: #2B2B35;
      --surface-three: #41414E;
      --surface-four: #DDDFEC;
      --surface-five: #24242C;
      --surface-muted: #16161E;
      --surface-warning: #644D31;
      --surface-danger: #973535;
      --surface-success: #459667;
      --blackout-overlay: rgba(0, 0, 0, 0.8);

      --neutral-lines-one: #404049;
      --neutral-lines-two: #2B2B30;
      --inputs: #2C2C33;
      --chips: #2D2D39;
      --chips-text: #9A9EA8;
      --print-borders-instead-of-fill: #D7DBE5;

      --text-primary: #FFFFFF;
      --text-secondary: #9A9EA8;
      --text-tertiary: #535861;
      --text-contrast: #101014;
      --active-accent: #7F9DEB;

      --button-primary: #2F50C7;
      --button-primary-text: #FFFFFF;
      --button-primary-hover: #2A44A0;
      --button-primary-pressed: #233A8A;
      --button-primary-focus-outline: #6383FF;
      --button-primary-loading: rgba(255, 255, 255, 0.25);

      --button-secondary-border: #404049;
      --button-secondary-border-bright: #69697B;
      --button-secondary-text: #FFFFFF;
      --button-secondary-hover: #2A2A31;
      --button-secondary-pressed: #201C21;
      --button-secondary-focus-outline: #373A47;
      --button-secondary-loading: #2C2D37;

      --button-danger-primary: #D34444;
      --button-danger-primary-text: #FFFFFF;
      --button-danger-primary-hover: #B13838;
      --button-danger-primary-pressed: #9B3030;
      --button-danger-primary-focus-outline: #7F3333;

      --button-danger-secondary-border: #D34444;
      --button-danger-secondary-text: #FFA9A9;
      --button-danger-secondary-hover: #30252B;
      --button-danger-secondary-pressed: #201C21;
      --button-danger-secondary-focus-outline: #692E2E;
      --button-danger-secondary-loading: #3A3036;

      --negative-accent: #E44C4C;
      --accent-one-bright-different: #3ED885;
      --accent-one-mellow-different: #459667;
      --accent-two: #3ED885;
      --surface-accent-two: #459667;
      --accent-three: #60B2FF;
      --accent-four: #977CFF;
      --accent-five: #F0884E;
      

      --user-steps-line: rgba(64, 64, 73, 0);
      --user-steps-line-full: #404049;
      --vacancy-card-tag-border: #404049;
      --active-accent2: #94A4DF;
      --step-bg-active: var(--surface-three);
      --stack-chip-border: #34343E;
      --skillset-tag-bg1: #34343E;
      --skillset-tag-bg2: #1F1F26;
    }
  }
}
