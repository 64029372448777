$accordion-margin-bottom: 16px;
$accordion-radius: 8px;

$accordion-header-padding: 10px 24px;

$accordion-title-margin: 12px;
$accordion-title-font-size: 14px;
$accordion-title-line-height: 20px;

$accordion-content-margin-top: 10px;
$accordion-content-margin-bottom: 24px;
$accordion-content-padding: 0 26px;