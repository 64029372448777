@import "../ui/variables";

.geecko-field {
  margin-bottom: 24px;

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
    color: $field-label-color;
    font-weight: $field-label-font-weight;
    font-size: $field-label-font-size;
    line-height: $field-label-line-height;
  }

  &__label {
    &--required {
      &::after
      {
        content: '*';
        position: relative;
        top: 2px;
        margin-left: 4px;
        font-size: 12px;
        color: $field-asterisk-color;
      }
    }
  }

  &__label-right {
    color: $primary;
  }

  &__error {
    font-size: 12px;
    line-height: 16px;
    color: var(--negative-accent);
    margin-top: 4px;
  }
}
