.profile__content-title {
  margin-top: 23px;
  margin-bottom: 30px;

  font-family: Gotham Pro, sans-serif;
  font-weight: bold;
  font-size: 36px;
  color: var(--text-primary);

  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1080px) {
  .profile__content-title {
    margin-top: 23px;
    margin-bottom: 23px;

    font-size: 24px;
  }
}

/* ------------------------ PERSON ------------------------ */

.person {
  display: flex;
  align-items: stretch;
}

/* ----------- AVATAR ----------- */

.person__avatar {
  margin-right: 16px;
  width: 87px;
  height: 87px;
  flex-shrink: 0;

  img {
    width: 87px;
    height: 87px;
    border-radius: 4px;
    display: block;
  }
}

/* ----------- INFO ----------- */

.person__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex-grow: 1;
}

/* NAME */

.person__info-name {
  margin-bottom: 11px;

  font-family: Gotham Pro, sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: var(--text-primary);
}

/* ID */

.person__info-id {
  font-family: Gotham Pro, sans-serif;
  font-size: 13px;
  color: var(--text-secondary);
}

/* SKILLS */

.person__info-skills {
  margin: -4px;

  display: flex;
  flex-wrap: wrap;
}

.person__info-skills-tag {
  margin: 4px;

  padding: 0 8px;

  height: 24px;

  font-family: Proxima Nova, sans-serif;
  font-size: 12px;
  user-select: none;
  color: var(--text-primary);

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid var(--neutral-lines-one);
  border-radius: 4px;

  box-sizing: border-box;
}

/* ------------------------ PERSON (@MEDIA) ------------------------ */

@media (max-width: 1080px) {
  .person {
    flex-direction: column;
    align-items: center;
  }

  .person__avatar {
    margin-bottom: 20px;
  }

  .person__info {
    margin-bottom: 20px;

    text-align: center;

    align-items: center;
  }

  .person__info-skills {
    margin-top: 10px;

    justify-content: center;
  }

  .person__rating {
    flex-direction: column-reverse;

    align-self: stretch;
  }

  .person__rating-value {
    margin-bottom: 20px;

    align-items: center;
  }

  .person__rating-edit {
    width: 100%;
    height: 32px;
  }
}

.person__section-label {
  margin-bottom: 5px;
  font-family: Proxima Nova, sans-serif;
  font-size: 0.72em;
  color: var(--text-secondary);
  font-weight: 600;

  display: flex;
}

.games {
  margin: -8px;

  display: flex;
  flex-wrap: wrap;
}

.games_empty {
  margin: 0;

  height: 56px;

  font-family: Proxima Nova, sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #858A9C;
  background: var(--surface-two);

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 4px;

  box-sizing: border-box;
}
