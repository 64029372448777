@import '../ui/variables';

.geecko-accordion {
  margin-bottom: $accordion-margin-bottom;

  border: 1px solid transparent;
  border-radius: $accordion-radius;

  box-sizing: border-box;

  overflow: hidden;

  transition: border 250ms;

  &--opened {
    border: 1px solid $accordion-opened-border-color;

    .geecko-accordion__header {
      background-color: transparent;
    }

    .geecko-accordion__arrow {
      transform: rotate(180deg);
    }
  }

  &__header {
    padding: $accordion-header-padding;

    user-select: none;
    color: $accordion-header-color;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    background: $accordion-header-background;

    box-sizing: border-box;

    cursor: pointer;

    transition: opacity 200ms, background-color 250ms;

    &:hover {
      opacity: 0.75;
    }

    &--has-icon {
      .geecko-accordion__title {
        margin-left: $accordion-title-margin;
      }
    }
  }

  &__title {
    font-size: $accordion-title-font-size;
    line-height: $accordion-title-line-height;
    color: var(--text-primary);
    flex-grow: 1;
  }

  &__icon {
    svg {
      display: block;
      color: var(--text-primary);
    }
  }

  &__arrow {
    transition: transform 300ms;
    color: $accordion-arrow-color;
  }

  &__content {
    padding: $accordion-content-padding;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    &--before {
      height: $accordion-content-margin-top;
    }

    &--after {
      height: $accordion-content-margin-bottom;
    }
  }
}

.geecko-accordion__buttons {
  display: flex;
}

.geecko-button--cancel {
  color: #060C38;
  opacity: 0.5;
  font-family: Proxima Nova, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  padding: 0 4px;
}

@media (max-width: 1080px) {
  .geecko-accordion__buttons {
    flex-direction: column;
  }

  .geecko-button--cancel {
    margin-top: 16px;
  }
}
