@import "../ui/variables";

.geecko-phone-input {
  position: relative;
  width: 100%;

  input[type=tel] {
    width: 100%;
    box-sizing: border-box;
    color: $input-text-color;
    font-weight: 500;
    font-size: 16px;
    line-height: $input-line-height;
    border-radius: $input-border-radius;
    padding: $input-padding;
    border: 1px solid $input-border-color;
    background: $input-background;

    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $input-placeholder-color;
    }

    &:focus,
    &:active {
      outline: none;
      background: $input-active-background;
      border: 1px solid $input-active-border-color;
      color: $input-active-text-color;
    }
  }

  &.is-error input[type=tel],
  input[type=tel]:invalid {
    border-color: $input-error-border-color;
  }
}
