@import './variables/colors';
@import './variables/buttons';
@import './variables/inputs';
@import './variables/tags';
@import './variables/alerts';
@import './variables/poppers';
@import './variables/accordions';

$size-large-line-height: 48px;
$size-large-font-size: 26px;

$size-medium-line-height: 36px;
$size-medium-font-size: 18px;

$size-default-line-height: 24px;
$size-default-font-size: 12px;

$size-small-line-height: 18px;
$size-small-font-size: 10px;

$size-mini-line-height: 12px;
$size-mini-font-size: 7px;

$radius-default: 4px;
$radius-rounded: 24px;
$radius-circle: 9999px;

