@import "../ui/variables";

.geecko-async-select {
  position: relative;
  background: var(--inputs);

  &__arrow {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 13px;
    right: 17px;
    z-index: 10;
    color: var(--text-tertiary);

    svg {
      transition: transform 0.275s;
    }
  }

  &--open {
    .geecko-async-select__arrow {
      svg {
        transform: rotate(180deg);
        
      }
    }
  }

  &__options {
    background-color: var(--surface-two);
    border-radius: 8px;
    margin-top: 2px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    top: calc(100% + 3px);
    left: 0;
    right: 0;
    position: absolute;
    z-index: 500;
    overflow-y: auto;
    scrollbar-width: 8px;

    & > * {
      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    /* width */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--surface-two);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--surface-four);
      border-radius: 4px;
    }
  }

  &__option {
    width: 100%;
    font-size: 14px;
    box-sizing: border-box;
    padding: 8px 12px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    border: none;
    background: none;
    position: relative;
    cursor: pointer;
    text-align: left;
    color: var(--text-primary);


    &:disabled,
    &:disabled:hover,
    &:disabled:focus {
      opacity: 0.5;
      cursor: not-allowed;
      background: var(--surface-three);
      font-weight: normal;
      user-select: none;
    }

    &:hover {
      background: var(--surface-three);
    }

    &:focus {
      background: $input-active-background;
      border-radius: 4px;
      z-index: 6;
    }

    &--selected {
      background: var(--surface-default);
      border-radius: 4px;
      z-index: 6;
    }

    &--current {
      font-family: var(--font-proxima-nova-semibold);
    }

    &-image {
      width: 24px;
      height: 24px;
      margin-right: 10px;

      img {
        width: 24px;
        height: 24px;
      }
    }

    div {
      &:first-child {
        margin-right: 15px;
      }
    }
  }

  &__option-text-matched {
    background: $input-active-background;
  }

  &__option-subtitle {
    margin-right: 15px;
    color: var(--gray-color--70);
    font-size: 14px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__options-placeholder {
    padding: 8px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--gray-color--70);
  }

  &__create-option {
    padding: 0 4px;
    border-radius: 4px;
    background: var(--brand-color--10);
    margin-left: 4px;
  }

  &__value-button {
    box-sizing: border-box;
    width: 100%;
    outline: none;
    position: relative;
    text-align: left;
    cursor: pointer;
    font-family: var(--font-proxima-nova-regular);
    color: $input-text-color;
    font-weight: 500;
    font-size: 16px;
    line-height: $input-line-height;
    border-radius: $input-border-radius;
    padding: 12px 16px;
    border: 1px solid $input-border-color;
    background: $input-background;

    &--size {
      &-medium {
        padding-top: 9px;
        padding-bottom: 9px;
        min-height: 46px;
      }

      &-big {
        height: 45px;
      }
    }

    &:hover {
      border-color: var(--button-secondary-border-bright);
      transition: border-color .275s;
    }

    &:focus {
      border-color: var(--button-secondary-border-bright) !important;
      box-shadow: 0 0 0 4px var(--button-secondary-focus-outline);
      transition: box-shadow .15s;
    }

    &--is-invalid,
    &--is-invalid:hover {
      border-color: $input-error-border-color !important;
  

      &::selection {
        background: var(--red-color--10);
      }
    }

    &--is-invalid:focus,
    &--is-invalid:focus:hover {
      border-color: $input-error-border-color !important;
      box-shadow: 0 0 0 4px var(--button-danger-secondary-focus-outline);
      transition: box-shadow .15s;
    }
  }
}
