.error-boundary-layout {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__inner {
    font-size: 16px;
  }
}
