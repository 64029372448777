@import '../ui/variables.scss';

.geecko-social-button {
  @apply sm:max-w-[227px] flex items-center overflow-ellipsis overflow-hidden m-2 border-none px-6 w-full cursor-pointer;
  --lightness: 13%;
  --hue: 0;
  --saturation: 0%;
  --bg-icon: hsl(var(--hue), var(--saturation), var(--lightness));
  background-color: var(--bg-icon) !important;
  height: 56px;
  font-weight: 500;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  width: 100%;

  &__icon {
    width: 24px;
    margin-right: 12px;
  }

  &:focus {
    outline: none;
  }

  &--vkontakte {
    --hue: 212;
    --saturation: 28%;
    --lightness: 52%;

    &:hover,
    &.is-hovered {
      --lightness: 57%;
    }

    &:focus,
    &.is-focused {
      &:not(:active) {
        box-shadow: 0 0 0 0.125em rgb(99 131 168 / 25%);
      }
    }

    &:active,
    &.is-active {
      --lightness: 42%;
    }

    &[disabled],
    fieldset[disabled] & {
      --bg-icon: #6383a8;
      box-shadow: none;
    }
  }

  &--facebook {
    --hue: 221;
    --saturation: 44%;
    --lightness: 41%;

    &:hover,
    &.is-hovered {
      --lightness: 46%;
    }

    &:focus,
    &.is-focused {
      &:not(:active) {
        box-shadow: 0 0 0 0.125em rgb(99 131 168 / 25%);
      }
    }

    &:active,
    &.is-active {
      --lightness: 41%;
    }

    &[disabled],
    fieldset[disabled] & {
      --bg-icon: #3b5998;
      box-shadow: none;
    }
  }

  &--linkedin {
    --hue: 209;
    --saturation: 55%;
    --lightness: 45%;

    &:hover,
    &.is-hovered {
      --lightness: 50%;
    }

    &:focus,
    &.is-focused {
      &:not(:active) {
        box-shadow: 0 0 0 0.125em rgb(51 117 178 / 25%);
      }
    }

    &:active,
    &.is-active {
      --lightness: 40%;
    }

    &[disabled],
    fieldset[disabled] & {
      --bg-icon: #3375b2;
      box-shadow: none;
    }
  }

  &--github {
    --hue: 240;
    --saturation: 10%;
    --lightness: 19%;

    &:hover,
    &.is-hovered {
      --lightness: 24%;
    }

    &:focus,
    &.is-focused {
      &:not(:active) {
        box-shadow: 0 0 0 0.125em rgb(6 12 56 / 25%);
      }
    }

    &:active,
    &.is-active {
      --lightness: 14%;
    }

    &[disabled],
    fieldset[disabled] & {
      --bg-icon: #040721;
      box-shadow: none;
    }
  }

  &--email {
    --hue: 240;
    --saturation: 10%;
    --lightness: 19%;

    &:hover,
    &.is-hovered {
      --lightness: 24%;
    }

    &:focus,
    &.is-focused {
      &:not(:active) {
        box-shadow: 0 0 0 0.125em hsl(0deg 0% 100% / 25%);
      }
    }

    &:active,
    &.is-active {
      --lightness: 14%;
    }

    &[disabled],
    fieldset[disabled] & {
      box-shadow: none;
    }
  }

  &--google {
    --hue: 240;
    --saturation: 10%;
    --lightness: 19%;

    &:hover,
    &.is-hovered {
      --lightness: 24%;
    }

    &:focus,
    &.is-focused {
      &:not(:active) {
        box-shadow: 0 0 0 0.125em hsl(0deg 0% 100% / 25%);
      }
    }

    &:active,
    &.is-active {
      --lightness: 14%;
    }

    &[disabled],
    fieldset[disabled] & {
      box-shadow: none;
    }
  }

  &--tg {
    --hue: 205;
    --saturation: 66%;
    --lightness: 59%;

    &:hover,
    &.is-hovered {
      --lightness: 64%;
    }

    &:focus,
    &.is-focused {
      &:not(:active) {
        box-shadow: 0 0 0 0.125em rgb(51 117 178 / 25%);
      }
    }

    &:active,
    &.is-active {
      --lightness: 54%;
    }

    &[disabled],
    fieldset[disabled] & {
      --bg-icon: #3375b2;
      box-shadow: none;
    }
  }

  &--tonkeeper {
    --hue: 219;
    --saturation: 32%;
    --lightness: 12%;

    &:hover,
    &.is-hovered {
      --lightness: 17%;
    }

    &:focus,
    &.is-focused {
      &:not(:active) {
        box-shadow: 0 0 0 0.125em rgb(51 117 178 / 25%);
      }
    }

    &:active,
    &.is-active {
      --lightness: 7%;
    }

    &[disabled],
    fieldset[disabled] & {
      --bg-icon: #3375b2;
      box-shadow: none;
    }
  }

  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &--featured {
    position: relative;
  }

  &--featured:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 36px 36px 0;
    border-color: transparent #fbb225 transparent transparent;
    right: 0;
    top: 0;
    position: absolute;
  }

  &--featured:hover::after {
    --bg-icon: #fbb225;
  }

  &--featured p {
    position: absolute;
    top: 3px;
    right: 3px;
    z-index: 10;
  }
}
