@import '../ui/variables';
@import '../ui/mixins.scss';

.geecko-tags-list {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 0;
  padding-left: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;

  .geecko-tag {
    margin-top: 6px;
    margin-bottom: 8px;
    margin-right: 8px;
    // cursor: pointer;
    // outline: none;
    // transition: box-shadow 0.15s ease;
    @apply inline-flex rounded font-semibold rounded-lg outline-0 select-none items-center py-1 h-10 px-4 text-sm;

    &:focus-visible {
      outline: 4px solid $focus-shadow-color;
      transition: outline .15s;
      // box-shadow: 0 0 0 4px $focus-shadow-color;
    }

    &:last-child {
      margin-right: 0;
    }

    &.is-inactive {
      opacity: 0.5;
    }

    &.is-info {
      &.is-active {
        // border: $tag-border-width solid $primary;
        // padding: $tag-padding-active;
        background: var(--surface-four);
        color: var(--text-contrast);
      }
    }
  }
  &:last-child {
    margin-bottom: -8px;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.geecko-tag:not(body) {
  align-items: center;
  background-color: $tag-default-background;
  border-radius: $tag-border-radius;
  color: $tag-default-color;
  display: inline-flex;
  font-size: $tag-font-size;
  justify-content: center;
  line-height: $tag-line-height;
  white-space: nowrap;
  font-style: normal;
  font-weight: $tag-font-weight;
  padding: $tag-padding;
  border: 1px solid $tag-default-border;
  @each $name, $pair in $tag-colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      background-color: $color;
      border-color: $color;
      color: $color-invert;

      // If a light and dark colors are provided
      @if length($pair) > 3 {
        $color-light: nth($pair, 3);
        $color-dark: nth($pair, 4);
        &.is-light {
          background-color: $color-light;
          border-color: $color-light;
          color: $color-dark;
        }
      }
    }
  }

  &.is-size-mini {
    font-size: $size-mini-font-size;
    line-height: $size-mini-line-height;
  }

  &.is-size-small {
    font-size: $size-small-font-size;
    line-height: $size-small-line-height;
  }

  &.is-size-medium {
    font-size: $size-medium-font-size;
    line-height: $size-medium-line-height;
  }

  &.is-size-large {
    font-size: $size-large-font-size;
    line-height: $size-large-line-height;
  }

  &.is-rounded {
    @apply rounded-lg;
  }

  &.is-clickable {
    cursor: pointer;
  }

  &.is-skeleton {
    color: transparent;
    border: none;
    @include geecko-skeleton(56px, 18px);
  }
}
