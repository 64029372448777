.geecko-auth-layout {
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &__background {
    position: absolute;

    top: -30px;
    bottom: -30px;
    left: -30px;
    right: -30px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    filter: blur(17px);

    z-index: -1;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(26, 27, 53, 0) -60.51%, #232331 100%);
    }
  }

  &__content {
    margin: 0 auto;

    width: 100%;
    height: 650px;

    max-width: 1280px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    border-radius: 30px;

    box-sizing: border-box;

    overflow: hidden;
    transition: width 250ms, height 250ms, max-width 250ms, max-height 250ms, border-radius 250ms;
  }

  &__content-left {
    padding: 0 90px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 50%;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    box-sizing: border-box;

    transition: padding 250ms;

    &::-webkit-scrollbar {
      background-color: transparent;
    }
  }

  &__content-left-features {
    color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__content-left-features-title {
    margin-bottom: 8px;

    font-family: 'Gotham Pro', sans-serif;
    font-weight: 500;
    font-size: 0.85em;
  }

  &__content-left-features-description {
    margin-bottom: 16px;

    font-family: Proxima Nova, sans-serif;
    font-weight: 300;
    font-size: 0.85em;
    line-height: 1.4em;

    opacity: 0.9;
  }

  &__content-top {
    margin-top: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: margin 250ms;
  }

  &__content-top-logo {
    position: relative;

    width: 120px;
    height: 40px;

    user-select: none;

    display: flex;
    align-items: center;

    background-position: 0 50%;
    background-repeat: no-repeat;
    background-size: 100%;

    &--geecko {
      background-image: url('../images/logo.svg');
      background-size: 90%;
    }
  }

  &__content-bottom {
    padding-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: margin 250ms;
  }

  &__content-bottom-signin {
    font-family: Proxima Nova, sans-serif;
    font-weight: bold;
    font-style: normal;
    font-size: 0.73em;
    color: var(--text-secondary);
  }

  &__content-bottom-signin-link {
    color: var(--active-accent);
    background: none;
    border: none;
    cursor: pointer;

    transition: color 130ms;
  }

  &__content-bottom-signin-link:hover {
    color: #4f74f9;
  }

  &__content-bottom-language {
    font-family: Proxima Nova, sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: #858a9c;

    display: flex;
    align-items: center;

    cursor: pointer;

    transition: opacity 130ms;
  }

  &__content-bottom-language:hover {
    opacity: 0.8;
  }

  &__content-bottom-back {
    display: flex;
    align-items: center;

    font-family: Proxima Nova, sans-serif;
    font-size: 0.78em;
    color: #dadde6;
    user-select: none;

    cursor: pointer;

    transition: opacity 130ms;

    &::before {
      content: '';

      margin-right: 8px;

      width: 13px;
      height: 8px;

      background-image: url('../images/icons/arrow2.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;

      transition: transform 200ms;
    }

    &:hover {
      opacity: 0.8;

      &::before {
        transform: translateX(-4px);
      }
    }
  }

  &__content-top {
    margin-top: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    transition: margin 250ms;
  }

  &__content-top-steps {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__content-top-steps-delimiter {
    margin: 0 8px;

    width: 4px;
    height: 1px;

    background-color: var(--neutral-lines-one);
  }

  &__locale {
    font-family: Proxima Nova, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #858a9c;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  &__content-top-steps-step {
    width: 24px;
    height: 24px;

    font-family: 'Gotham Pro', sans-serif;
    font-weight: 500;
    font-size: 0.61em;
    /* color: #858a9c; */
    color: var(--text-secondary);
    user-select: none;

    display: flex;
    justify-content: center;
    align-items: center;

    /* background: #f5f6f8; */

    border-radius: 32px;
    border: 1px solid var(--neutral-lines-one);
    transition: color 150ms, background-color 150ms, box-shadow 150ms;

    &--active,
    &--complete {
      /* color: #060c38;

      background: #ffffff;
      box-shadow: 0 2px 16px rgba(26, 27, 53, 0.11); */
      border-color: var(--active-accent2);
      cursor: pointer;
    }

    &--active {
      background: var(--step-bg-active);
      &:hover {
        /* color: #989898; */
      }
    }

    &--complete {
      background-image: url('../images/icons/mark.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 40%;

      &::before {
        content: '' !important;
      }
    }

    &:nth-last-of-type {
      margin-right: 0;
    }

    &:nth-of-type(1)::before {
      content: '1';
    }

    &:nth-of-type(3)::before {
      content: '2';
    }

    &:nth-of-type(5)::before {
      content: '3';
    }

    &:nth-of-type(7)::before {
      content: '4';
    }
  }

  &__content-right {
    padding: 0 80px;

    display: flex;
    flex-direction: column;
    flex-basis: 50%;

    background-color: var(--surface-default);

    box-sizing: border-box;

    overflow-y: scroll;

    transition: padding 250ms;

    &::-webkit-scrollbar {
      background-color: transparent;
    }
  }

  &__content-spacer {
    flex: 1;
  }
}

@media (max-width: 1400px) {
  .geecko-auth-layout__content {
    width: 100%;
    height: 100vh;
    max-width: 100%;
    border-radius: 0;
    padding: 0;
  }

  .geecko-auth-layout__content-left,
  .geecko-auth-layout__content-right {
    padding: 0 70px;
  }
}

@media (max-width: 1190px) {
  .geecko-auth-layout__content-left,
  .geecko-auth-layout__content-right {
    padding: 0 24px;
  }

  .geecko-auth-layout__background {
    display: none;
  }
}

@media (max-width: 1023px) {
  .geecko-auth-layout {
    overflow: visible;
  }

  .geecko-auth-layout__content {
    flex-direction: column;

    background-color: transparent;

    overflow: visible;
  }

  .geecko-auth-layout__content-left,
  .geecko-auth-layout__content-right {
    flex-basis: auto;

    overflow: visible;
  }

  .geecko-auth-layout__content-left {
    position: fixed;

    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    border-radius: 0;
  }

  .geecko-auth-layout__content-right {
    position: relative;

    margin-top: 60vh;

    border-radius: 24px 24px 0 0;
    min-height: max-content;
  }

  .geecko-auth-layout__content-right::before {
    content: '';

    position: absolute;

    top: 10px;

    width: 22px;
    height: 2px;

    align-self: center;

    background-color: #dadde6;
  }
}

.agreement {
  margin: 0;
  font-family: Proxima Nova, sans-serif;
  font-size: 0.75em;
  line-height: 1.38em;
  color: #858a9c;
}

.signin__or {
  width: 100%;
  font-family: 'Proxima Nova', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 12px;
  color: #dadde6;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::before,
  &::after {
    content: '';
    width: calc(50% - 40px);
    height: 1px;
    background: #dadde6;
  }
}

.geecko-profile__social-networks {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

[data-geecko-theme='dark'] .geecko-auth-layout {
  &__content-top-logo--geecko {
    background-image: url('../images/logo-dark.svg');
    background-size: 90%;
  }
}
