@import "./ui/variables";

body {
  margin: 0;
  padding: 0;
  font-family: 'Proxima Nova', sans-serif;
  min-height: 100vh;
  font-size: 16px;
  color: black;
  background-color: var(--background);
}

a {
  text-decoration: inherit;
  color: $primary;
}

a.link {
  text-decoration: underline;
  color: $primary;
}

::selection {
  color: white;
  background-color: #C7D0F1;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #D8D8D8;
}

::-webkit-scrollbar-thumb {
  background-color: #A0A0A0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--inputs) inset;
  -webkit-text-fill-color: var(--text-primary);
}

h1.title {
  margin: 0;

  font-family: 'Gotham Pro', sans-serif;
  font-weight: bold;
  font-style: normal;

  font-size: 1.9em;
  color: var(--text-primary);
  line-height: 1.2em;
}

h1.title_white {
  color: white;
}

.group-large {
  margin-top: 35px;
  margin-bottom: 35px;
}

.group-large-top {
  margin-top: 35px;
}

.group-large-bottom {
  margin-bottom: 35px;
}

.group-middle {
  margin-top: 22px;
  margin-bottom: 22px;
}

.group-middle-top {
  margin-top: 22px;
}

.group-middle-bottom {
  margin-bottom: 22px;
}

.group-small {
  margin-top: 13px;
  margin-bottom: 13px;
}

.group-small-top {
  margin-top: 13px;
}

.group-small-bottom {
  margin-bottom: 13px;
}

.logotype {
  width: 150px;

  min-height: 30px;

  background-image: url('./images/logo.svg');
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: 80%;

  flex-shrink: 0;

  cursor: pointer;

  transition: opacity 200ms;
}

[data-geecko-theme='dark'] .logotype {
  background-image: url('./images/logo-dark.svg');
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: 80%;
}

$spaceamounts: (8, 12, 16, 20, 24, 40, 72, 76, 80);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

.geecko-field, .geecko-input {
  font-family: Proxima Nova, sans-serif;
}

.geecko-button {
  font-family: 'Gotham Pro', sans-serif;
  font-weight: bold;
  font-style: normal;
}

.geecko-social-button {
  font-family: 'Gotham Pro', sans-serif;
  font-weight: bold;
  font-style: normal;
}

.geecko-tag {
  font-family: 'Proxima Nova', sans-serif;
  font-weight: 600;
  font-style: normal;
}

.geecko-accordion {
  &__title {
    font-family: Proxima Nova, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #101014;
  }
}

.label__link {
  font-family: Proxima Nova, sans-serif;
  font-weight: bold;
  font-style: normal;
}

.geecko__loading-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.geecko-label--gray {
  color: $input-text-color;
  font-family: Proxima Nova, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.geecko-color--heading {
  color: var(--text-primary);
}

.geecko-color--text-main {
  color: var(--text-primary);
}

.geecko-color--on-surface--primary {
  color: var(--text-primary);
}

.geecko-color--on-surface--secondary {
  color: #4d4e57;
}

.geecko-color--gray {
  color: $input-text-color;
}

.text-on-surface-tertiary {
  color: var(--text-tertiary);
}

.font-gotham {
  font-family: 'Gotham Pro', sans-serif;
}

[multiple],
[type=date],
[type=datetime-local],
[type=email],
[type=month],
[type=number],
[type=password],
[type=search],
[type=tel],
[type=text],
[type=time],
[type=url],
[type=week],
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem
}

[multiple]:focus,
[type=date]:focus,
[type=datetime-local]:focus,
[type=email]:focus,
[type=month]:focus,
[type=number]:focus,
[type=password]:focus,
[type=search]:focus,
[type=tel]:focus,
[type=text]:focus,
[type=time]:focus,
[type=url]:focus,
[type=week]:focus,
select:focus,
textarea:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,);
  /*!*//*!*/
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  border-color: #2563eb
}

body[data-geecko-theme="dark"] {
  [type=date]::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
}


input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #6b7280;
  opacity: 1
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #6b7280;
  opacity: 1
}

input::placeholder,
textarea::placeholder {
  color: #6b7280;
  opacity: 1
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0
}

::-webkit-date-and-time-value {
  min-height: 1.5em
}

select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  padding-right: .75rem;
  -webkit-print-color-adjust: unset;
  print-color-adjust: unset;
}

[type=checkbox],
[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: var(--accent-one-mellow-different);
  background-color: var(--surface-three);
}

[type=checkbox] {
  border-radius: 0
}

[type=radio] {
  border-radius: 100%
}

[type=checkbox]:focus-visible,
[type=radio]:focus-visible {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,);
  /*!*//*!*/
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

[type=checkbox]:checked,
[type=radio]:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat
}

[type=checkbox]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e")
}

[type=radio]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e")
}

[type=checkbox]:checked:focus,
[type=checkbox]:checked:hover,
[type=radio]:checked:focus,
[type=radio]:checked:hover {
  border-color: transparent;
  background-color: currentColor
}

[type=checkbox]:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat
}

[type=checkbox]:indeterminate:focus,
[type=checkbox]:indeterminate:hover {
  border-color: transparent;
  background-color: currentColor
}

[type=file] {
  background: unset;
  border-color: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-size: unset;
  line-height: inherit
}

[type=file]:focus {
  outline: 1px auto -webkit-focus-ring-color
}

/**
 * This class name is a commonly used method to hide text visually but leaves it accessible to screen readers.
 */
.visually-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
