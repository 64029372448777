$field-label-font-weight: 600;
$field-label-font-size: 14px;
$field-label-line-height: 20px;

$input-font-weight: 600;
$input-font-size: 12px;
$input-line-height: 20px;

$input-border-radius: 8px;
$input-padding-left: 16px;
$input-padding-top: 12px;
$input-padding: $input-padding-top $input-padding-left;
