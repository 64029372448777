@import "../functions";

$primary: #2F50C7;
$primary-invert: findColorInvert($primary) !default;
$primary-light: findLightColor($primary) !default;
$primary-dark: findDarkColor($primary) !default;

$focus-shadow-color: var(--button-primary-focus-outline);

$info: var(--surface-two) !default;
$info-invert: var(--text-secondary) !default;
$info-light: $info !default;
// $info-light: findLightColor($info) !default;
$info-dark: #1A348F !default;

$success: hsl(153, 53%,  53%) !default;
$success-invert: findColorInvert($success) !default;
$success-light: findLightColor($success) !default;
$success-dark: findDarkColor($success) !default;

$warning: #FCF7E3 !default;
$warning-invert: findColorInvert($warning) !default;
$warning-light: findLightColor($warning) !default;
$warning-dark: findDarkColor($warning) !default;

$danger: #D83A3A !default;
$danger-invert: findColorInvert($danger) !default;
$danger-light: findLightColor($danger) !default;
$danger-dark: findDarkColor($danger) !default;

$github: #060C38;
$github-invert: findColorInvert($github) !default;
$github-light: findLightColor($github) !default;
$github-dark: findDarkColor($github) !default;

$google: #FFFFFF;
$google-invert: #101014 !default;
$google-light: findLightColor($google) !default;
$google-dark: findDarkColor($google) !default;

$facebook: #3B5998;
$facebook-invert: findColorInvert($facebook) !default;
$facebook-light: findLightColor($facebook) !default;
$facebook-dark: findDarkColor($facebook) !default;

$linkedin: #3375B2;
$linkedin-invert: findColorInvert($linkedin) !default;
$linkedin-light: findLightColor($linkedin) !default;
$linkedin-dark: findDarkColor($linkedin) !default;

$vk: #6383A8;
$vk-invert: findColorInvert($vk) !default;
$vk-light: findLightColor($vk) !default;
$vk-dark: findDarkColor($vk) !default;

$email: #FFFFFF;
$email-invert: #101014 !default;
$email-light: findLightColor($email) !default;
$email-dark: findDarkColor($email) !default;

$grey-darker:  hsl(0, 0%, 21%) !default;
$white-ter:    hsl(0, 0%, 96%) !default;

$black: hsl(0, 0%, 4%) !default;
$white: hsl(0, 0%, 100%) !default;

$custom-colors: null !default;

$colors: mergeColorMaps(
    (
        "white": ($white, $black),
        "black": ($black, $white),
        "primary": ($primary, $primary-invert, $primary-light, $primary-dark),
        "info": ($info, $info-invert, $info-light, $info-dark),
        "success": ($success, $success-invert, $success-light, $success-dark),
        "warning": ($warning, $warning-invert, $warning-light, $warning-dark),
        "danger": ($danger, $danger-invert, $danger-light, $danger-dark),
    ),
    $custom-colors
);


$button-text-hover-background-color: hsl(0, 0%, 96%) !default;
$button-text-hover-color: hsl(0, 0%, 21%) !default;
$button-text-color:  #060C38 !default;

$social-colors: (
    "github": ($github, $github-invert, $github-light, $github-dark),
    "google": ($google, $google-invert, $google-light, $google-dark),
    "facebook": ($facebook, $facebook-invert, $facebook-light, $facebook-dark),
    "linkedin": ($linkedin, $linkedin-invert, $linkedin-light, $linkedin-dark),
    "vkontakte": ($vk, $vk-invert, $vk-light, $vk-dark),
    "email": ($email, $email-invert, $email-light, $email-dark),
);

$field-label-color: var(--text-primary);
$field-asterisk-color: #D94646;

$input-border-color: var(--neutral-lines-one);
$input-background: var(--inputs);
$input-text-color: var(--text-secondary);

$input-active-border-color: var(--active-accent2);
$input-active-background: var(--inputs);
$input-active-text-color: var(--text-secondary);

$input-error-border-color: var(--button-danger-secondary-border);

$input-placeholder-color: #858A9C;

$tag-default-background: var(--surface-two);
$tag-default-color: var(--text-secondary);
$tag-default-border: #DADDE6;

$alert-default-background: #f5f5f5;
$alert-default-color: #4a4a4a;

$dropdown-color: #858A9C;
$dropdown-background: #FFFFFF;
$dropdown-border-color: #F8F8F8;

$accordion-opened-border-color: var(--neutral-lines-one);
$accordion-header-color: #101014;
$accordion-header-background: var(--surface-two);
$accordion-arrow-color: var(--text-tertiary);

$button-colors: $colors;
$tag-colors: $colors;
$alert-colors: $colors;
$toast-colors: $colors;
