@font-face {
  font-family: 'Gotham Pro';
  src: url('./fonts/GothamPro/GothamPro-Medium.woff2') format('woff2'),
  url('./fonts/GothamPro/GothamPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('./fonts/GothamPro/GothamPro-Bold.woff2') format('woff2'),
  url('./fonts/GothamPro/GothamPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('./fonts/GothamPro/GothamPro.woff2') format('woff2'),
  url('./fonts/GothamPro/GothamPro.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./fonts/ProximaNova/ProximaNova-Regular.woff2') format('woff2'),
  url('./fonts/ProximaNova/ProximaNova-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./fonts/ProximaNova/ProximaNova-Semibold.woff2') format('woff2'),
  url('./fonts/ProximaNova/ProximaNova-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./fonts/ProximaNova/ProximaNova-Bold.woff2') format('woff2'),
  url('./fonts/ProximaNova/ProximaNova-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./fonts/ProximaNova/ProximaNova-Light.woff2') format('woff2'),
  url('./fonts/ProximaNova/ProximaNova-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Joystix';
  src: url('./fonts/Joystix/Joystix.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
