@import '../ui/variables.scss';
@import '../ui/mixins.scss';

.geecko-button {
  &__icon {
    margin-right: 8px;
    line-height: 0;

    &.is-icon-only {
      margin: 0;
    }
  }
  border-radius: $radius-default;
  position: relative;
  padding: 8px 17px;
  font-style: normal;
  font-weight: 600;
  font-size: $size-default-font-size;
  line-height: $size-default-line-height;
  cursor: pointer;
  border: 1px solid transparent;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  display: flex;

  &.is-icon {
    padding: 4px;
  }

  &.is-circle {
    border-radius: $radius-circle;
  }

  &.is-icon-right {
    flex-direction: row-reverse;

    & .geecko-button__icon {
      margin-left: 8px;
      margin-right: 0;
    }
  }

  &:focus {
    outline: none;
  }

  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
  }

  &.is-size-mini {
    font-size: $size-mini-font-size;
    line-height: $size-mini-line-height;
  }

  &.is-size-small {
    font-size: $size-small-font-size;
    line-height: $size-small-line-height;
  }

  &.is-size-medium {
    font-size: $size-medium-font-size;
    line-height: $size-medium-line-height;
  }

  &.is-size-large {
    font-size: $size-large-font-size;
    line-height: $size-large-line-height;
  }

  &.is-full-width {
    display: flex;
    width: 100%;
  }

  &.is-rounded {
    border-radius: $radius-rounded;
  }

  &.is-loading {
    color: transparent !important;
    pointer-events: none;
    &::after {
      @include loader;
      @include center(18px);
      position: absolute !important;
    }
  }

  &.is-text {
    background-color: transparent;
    border-color: transparent;
    color: $button-text-color;
    text-decoration: $button-text-decoration;
    opacity: 0.5;
    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused {
      background-color: $button-text-hover-background-color;
      color: $button-text-hover-color;
    }

    &:active,
    &.is-active {
      background-color: geeckoDarken($button-text-hover-background-color, 5%);
      color: $button-text-hover-color;
    }

    &[disabled],
    fieldset[disabled] & {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
    }
  };

  @each $name, $pair in $button-colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    transition: box-shadow 0.15s ease;

    &.is-#{$name} {
      background-color: $color;
      border-color: transparent;
      color: $color-invert;
      &:hover,
      &.is-hovered {
        background-color: geeckoDarken($color, 2.5%);
        border-color: transparent;
        color: $color-invert;
      }

      &:focus,
      &.is-focused {
        border-color: transparent;
        color: $color-invert;
        &:not(:active) {
          box-shadow: 0 0 0 4px $focus-shadow-color;
        }
      }

      &.is-loading {
        &::after {
          border-color: transparent transparent $color-invert $color-invert !important
        }
      }

      &:active,
      &.is-active {
        background-color: geeckoDarken($color, 5%);
        border-color: transparent;
        color: $color-invert;
      }

      &[disabled],
      fieldset[disabled] & {
        background-color: $color;
        border-color: transparent;
        box-shadow: none ;
      }

      &.is-inverted {
        background-color: $color-invert;
        color: $color;
        &:hover,
        &.is-hovered {
          background-color: geeckoDarken($color-invert, 5%);
        }

        &[disabled],
        fieldset[disabled] & {
          background-color: $color-invert;
          border-color: transparent;
          box-shadow: none;
          color: $color;
        }
      }

      &.is-outlined {
        background-color: transparent;
        border-color: $color;
        color: $color;
        &:hover,
        &.is-hovered,
        &:focus,
        &.is-focused {
          background-color: $color;
          border-color: $color;
          color: $color-invert;
        }
        &.is-loading {
          &::after {
            border-color: transparent transparent $color $color !important;
          }
        }

        &:hover,
        &.is-hovered,
        &:focus,
        &.is-focused {
          &::after {
            border-color: transparent transparent $color-invert $color-invert !important
          }
        }
        &[disabled],
        fieldset[disabled] & {
          background-color: transparent;
          border-color: $color;
          box-shadow: none;
          color: $color;
        }
      }

      &.is-inverted.is-outlined {
        background-color: transparent;
        border-color: $color-invert;
        color: $color-invert;
        &:hover,
        &.is-hovered,
        &:focus,
        &.is-focused {
          background-color: $color-invert;
          color: $color;
        }

        &.is-loading {
          &:hover,
          &.is-hovered,
          &:focus,
          &.is-focused {
            &::after {
              border-color: transparent transparent $color $color !important;
            }
          }
        }

        &[disabled],
        fieldset[disabled] & {
          background-color: transparent;
          border-color: $color-invert;
          box-shadow: none;
          color: $color-invert;
        }
      }

      @if length($pair) >= 4 {
        $color-light: nth($pair, 3);
        $color-dark: nth($pair, 4);

        &.is-light {
          background-color: $color-light;
          color: $color-dark;
          font-weight: normal;
          &:hover,
          &.is-hovered {
            background-color: geeckoDarken($color-light, 2.5%);
            border-color: transparent;
            color: $color-dark;
          }

          &:active,
          &.is-active {
            background-color: geeckoDarken($color-light, 5%);
            border-color: transparent;
            color: $color-dark;
          }
        }
      }
    }
  }

  &[disabled],
  fieldset[disabled] & {
    opacity: 0.5;
  }
}
